import type { Recurrence } from '@lingoda/dates';
import type { ClassType, Currency } from '@lingoda/graphql';
import type { CurriculumId, SectionName } from '@lingoda/sections';

export interface AppointmentSchema {
    endDate: string;
    feedbackId?: number;
    needsMissedClassFeedback?: boolean;
    id: string;
    moduleId: number;
    startDate: string;
    createdAt: string;
    status: 'active' | 'attended' | 'cancelled' | 'missed';
    statusByStudent?: 'completed' | 'not_attended' | 'no_teacher';
    studentId: number;
    isFirstBooked?: boolean;
}

export type ProductId = number;

export interface CouponSchema {
    id: number;
    code: string;
}

export interface ClassSchema {
    id: string;
    startDate: string;
    endDate: string;
    lessonId: number;
    moduleId: number;
    type: string;
    teacherId: number;
    status: string;
    cancelledByGroup: string;
    provider: string;
    annotatedPresentation: string;
    availableSeats: number;
}

export interface CurriculumSchema {
    id: number;
    name: string;
}

export interface FaqEntrySchema {
    id: number;
    question: string;
    answer: string;
    position: number;
    categoryId: number;
}

export interface FaqCategorySchema {
    id: number;
    iconKind: string;
    title: string;
    position: number;
}

export interface LearningUnitSchema {
    id: number;
    name: string;
    color: string;
    icon: string;
    moduleId: number;
    sectionId: number;
    position: number;
    lessonIds: number[];
    chapter: number;
}

export interface UserLearningUnitSchema extends Omit<LearningUnitSchema, 'lessonIds'> {
    lessons: LessonSchema[];
}

export enum StudentStatus {
    Completed = 'completed',
    Pending = 'pending',
    Booked = 'booked',
    NotTaken = 'not-taken',
    Skipped = 'skip',
    Retake = 'retake',
}

export enum LessonType {
    GRAMMAR = 'grammar',
    CONVERSATION = 'conversation',
    READING = 'reading',
    WRITING = 'writing',
    INDIVIDUAL = 'individual',
    SKILLS = 'skills',
    VOCABULARY = 'vocabulary',
    ORIENTATION = 'orientation',
    CUSTOM = 'custom',
}

export interface LessonSchema {
    id: number;
    title: string;
    slug: string;
    label: string;
    active: boolean;
    type: LessonType;
    seoMetadata: string;
    isOrientation: boolean;
    presentation: string;
    description: string;
    learningOutcome: string;
    learningUnitIds: number[];
    studentStatus: StudentStatus;
    completedCurrentVersion: boolean;
    availableWithoutPayment: boolean;
}

export interface PagerSchema {
    current: number;
    total: number;
}

export interface PostSchema {
    id: number;
    abstract: string;
    content: string;
    images: {
        big: string;
        medium: string;
        small: string;
    };
    publicationDateStart: string;
    title: string;
    section: {
        id: number;
        name: SectionName;
    };
    slug: string;
    socialShareUrl: string;
    tags: string[];
}

export interface PostsSchema {
    posts: PostSchema[];
    pager: PagerSchema;
}

interface PageBlockSchema {
    component: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: { [key: string]: any };
    children: PageBlockSchema[];
}

export interface PageSchema {
    id: number;
    name: string;
    title: string;
    navTitle: string;
    section: SectionName;
    path: string;
    defaultChildId: number;
    enabled: boolean;
    children: PageSchema[];
    contentBlocks: PageBlockSchema[];
}

export type StudentPreferencesSchema = {
    bookingFilter?: {
        time: {
            end: number;
            start: number;
        };
        type: string[];
        week: number[];
    };
    nextClass?: boolean;
    notifications?: {
        startTourCourse?: string;
    };
    oneClickBooking?: boolean;
    isProductTourFinished?: boolean;
    movedToNextModule?: boolean;
    npsFeedbackRequired?: boolean;
    timeFormat?: string | undefined;
    weekStart?: number | undefined;
    emailCancelledClassConfirmation?: boolean;
    emailBookedClassConfirmation?: boolean;
    emailClassReminder?: boolean;
    emailWeeklyReport?: boolean;
    showOrientationClassBanner?: boolean;
};

export interface StudentSchema {
    id: number;
    firstName: string;
    lastName: string;
    timezone: string;
    preferences: StudentPreferencesSchema;
    groupCredits: number;
    individualCredits: number;
    sectionId: number;
    moduleId: number;
    curriculumId: number;
    onboarded: boolean;
}

export interface SubscriptionSchema {
    allowPause?: boolean;
    allowCancel?: boolean;
    allowChange?: boolean;
    expiresAt?: string;
    id: number;
    lastChargedAt?: string;
    createdAt: string;
    months?: number;
    nextChargeAt?: string;
    price?: PriceSchema;
    productId?: ProductId;
    productVariantId?: number;
    pausedAt?: string;
    resumesAt?: string;
    status?: string;
    studentId?: number;
    isExpired: boolean;
    title: string;
    individualCredits: number;
    isRecurring: boolean;
    groupCredits: number;
    productType: string;
    recurrenceInterval?: Recurrence;
    creditsValidityInterval?: Recurrence;
}

export interface StudentModuleSchema extends ModuleSchema {
    completedClasses: number;
    totalClasses: number;
}

export interface StudentCreditSchema {
    availableAt: string;
    credits: number;
    expireAt: string;
    id: number;
    remainingCredits: number;
    source: string;
    type: string;
    usedCredits: number;
}

export interface TeacherSchema {
    id: number;
    firstName: string;
    canTeachGroupClass: boolean;
    canTeachPrivateClass: boolean;
    createdAt: string;
    photo: string;
    photos: {
        thumbnail: string;
        thumbnail2x: string;
        medium: string;
        medium2x: string;
        thumb_large: string;
        '': string;
        thumb_large2x: string;
    };
    teacherInfo: {
        aboutMe: string;
        classRating: number;
        country: string;
        firstName: string;
        largeThumbnail: string;
        spokenLanguages: {
            fluency: string;
            language: string;
        }[];
        teachLanguage: {
            id: number;
            name: SectionName;
        };
        thumbnail: string;
    };
    uid: string;
}

export interface UserSchema {
    id: number;
    firstName: string;
    photos?: {
        thumbnail: string;
    };
    sectionId?: number;
    studentId?: number;
    teacher?: boolean;
    timezone: string;
    fullName: string;
    email: string;
    sectionName: string;
    defaultContract?: {
        id: number;
        type: string;
        reference: string;
        displayNumber: string;
    };
    photo?: {
        thumbnail: string;
        thumbnail2x: string;
        medium: string;
        medium2x: string;
        thumbnail_large: string;
        thumbnail_large2: string;
    };
    now?: string;
    marketingAgreement?: {
        id: number;
        acceptanceText: string;
    };
    cancelledExpiresAt?: string;
    lastActiveAt?: string;
}

export interface StudentInfoSchema {
    id: number;
    invoiceAddress: string;
    birthCity: string;
    birthCountry: string;
}

export enum Gateway {
    ADYEN = 'adyen',
    STRIPE = 'stripe',
}

export interface PaymentMethodsSchema {
    id: string;
    logos: string[];
    methodName: string;
    name: string;
    note: string;
    stored: boolean;
    text: string;
    type: string;
    default: boolean;
    gateway: Gateway;
    currencies: Currency[];
}

export interface PaymentSchema {
    url?: string;
    success?: boolean;
    secret: string;
    purchaseId?: string;
}

export interface CountrySchema {
    id: number;
    code: string;
    telephoneCode: string;
    commonName: string;
    formalName: string;
}

export type ClassLeadTimeSchema = {
    [key in ClassType]: { [sectionKey in SectionName]: string };
};

export interface StudentInvoiceSchema {
    id: number;
    invoiceNr: string;
    pdfUrl: string;
    createdAt: string;
}

export interface TransactionSchema {
    id: number;
    processed: boolean;
    success: boolean;
    errorMessage: string;
}

export interface BlockSchema {
    categoryId: number;
    title: string;
    content: string;
    id: number;
    position: number;
}

export interface ModuleSchema {
    id: number;
    name: string;
    longName: string;
    code: string;
    description: string;
    position: number;
    cefrLevelName: string;
    learningUnitIds: number[];
    completedClasses: number;
    totalClasses: number;
}

export interface ProductSchema {
    id: number;
    individualCredits: number;
    isNonRecurring: boolean;
    groupCredits: number;
    type: string;
    isTrial: boolean;
    canUseCoupon: boolean;
    moduleId: number;
    rolloverProductId: number;
}

export interface PriceSchema {
    amount: number;
    currency: Currency;
    amountInMajor: number;
}

export interface ProductVariantSchema {
    id: number;
    productId: ProductId;
    price: PriceSchema;
    sectionId: number;
    productName: string;
    description: string | null;
    pricePerClass: PriceSchema;
}

export interface SectionSchema {
    id: number;
    name: SectionName;
}

export interface StartingModuleSchema {
    id: string;
    code: string;
    title: string;
    description: string;
    position: number;
    module: ModuleSchema;
    modules: ModuleSchema[];
}

export interface PracticeSchema {
    lessonId: number;
    lessonNumber: number;
    classId: number;
    classUniqueId: string;
    classLogId: number;
    moduleId: number;
    exerciseIds: string[];
    learningUnitId: number;
}

export interface RegisterUserParams {
    section: SectionName;
    curriculumId: CurriculumId;
    timezone: string;
    timeFormat: string;
    weekStart: number;
    module: number;
}
