import type { Date } from '@lingoda/dates';
import { createRecordFactory } from '@lingoda/utils';

export type NotificationId = string;

export type NotificationType = keyof NotificationPayload;

export interface Data {
    message?: string;
}

export interface NotificationPayload {
    sprintRules: undefined;
    startTourCourse: undefined;
    accountReactivated: undefined;
    courseExpired: undefined;
    recommend: undefined;
    moduleFinishing: undefined;
}

export interface Notification<T> {
    readonly id: NotificationId;
    readonly payload?: T;
    readonly type: NotificationType;
    readonly pending?: boolean;
    readonly seen?: boolean;
    readonly timestamp: Date;
    readonly dismissable?: boolean;
    readonly iconKey?: string;
}

export type PayloadMessage = string | { message: string; title: string };

export default createRecordFactory<Notification<unknown>>({
    id: undefined,
    payload: undefined,
    type: 'sprintRules',
    pending: true,
    seen: false,
    timestamp: undefined,
    dismissable: undefined,
});

export interface NotificationProps<T = string> {
    isSeen?: boolean; // TODO: this prop is only exposed until https://lingoda.atlassian.net/browse/LW-22676 has been finished
    notification: Notification<T>;
    onClose: (id: string) => void;
}
