import { createAction } from '@lingoda/core';
import type { Student, StudentPreferences } from '../models';

type Payload = Optional<Omit<Student, 'preferences'>, 'firstName'> & {
    preferences?: StudentPreferences;
};

export const updateStudent = createAction<Payload>('student/UPDATE_STUDENT');
export const addStudents = createAction<Student[]>('student/ADD_STUDENTS');

export { default as disableNotification } from './disableNotification';
export { default as disableMovedToNextModule } from './disableMovedToNextModule';
export { default as startTour } from './startTour';
export { default as setNotificationValue } from './setNotificationValue';
export { default as setModule } from './setModule';
export { default as updatePreferences } from './updatePreferences';
export { default as disableSprintRules } from './disableSprintRules';
export { default as startTourCourse } from './startTourCourse';
export { default as addStudentInfo } from './addStudentInfo';
export { default as updateBillingInfo } from './updateBillingInfo';
export { default as updateCertificateInfo } from './updateCertificateInfo';
export { default as finishProductTour } from './finishProductTour';
export { default as setOnboarded } from './setOnboarded';
