import { RetryLink } from '@apollo/client/link/retry';

export default () =>
    new RetryLink({
        attempts: {
            max: 5,
            retryIf: (error, operation) =>
                !operation.getContext()?.noRetry && !!error && error.statusCode !== 400,
        },
    });
