import { lazy } from 'react';
import {
    RouteAccess,
    teacherConfirmEmail,
    teacherRegistrationPath,
    teacherSignupConfirmationPath,
} from '@lingoda/router';

const TeacherSignupConfirmationPage = lazy(() =>
    import('./pages/TeacherSignupConfirmation').then(({ TeacherSignupConfirmation }) => ({
        default: TeacherSignupConfirmation,
    })),
);

const TeacherEmailConfirmationPage = lazy(() =>
    import('./pages/TeacherEmailConfirmation').then(({ TeacherEmailConfirmation }) => ({
        default: TeacherEmailConfirmation,
    })),
);

const TeacherRegistrationPage = lazy(() =>
    import('./pages/TeacherRegistration').then(({ TeacherRegistration }) => ({
        default: TeacherRegistration,
    })),
);

export const teacherRegistrationRoutes = [
    {
        component: TeacherSignupConfirmationPage,
        path: teacherSignupConfirmationPath.raw,
        label: 'TeacherSignupConfirmation',
        config: {
            access: RouteAccess.Public,
        },
    },
    {
        component: TeacherEmailConfirmationPage,
        path: teacherConfirmEmail.raw,
        label: 'TeacherEmailConfirmation',
        config: {
            access: RouteAccess.Public,
        },
    },
    {
        component: TeacherRegistrationPage,
        path: teacherRegistrationPath.raw,
        label: 'TeacherRegistration',
        config: {
            access: RouteAccess.Public,
        },
    },
];
